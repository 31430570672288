import React from 'react'

import styles from './burgerMenu.module.css'

interface Props {
  isActive: boolean
}

export const BurgerMenu: React.FC<Props> = ({ isActive }) => {
  return (
    <div className={isActive ? styles.active + ' ' + styles.menuToggle : styles.menuToggle}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}
