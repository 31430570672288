import React, { useState } from 'react'
import { useSpring, animated } from 'react-spring'

import styles from './mobile.module.css'

import { Links } from '../Links'
import { BurgerMenu } from './burgerMenu/BurgerMenu'

export const Mobile: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const slide = useSpring({
    transform: isMenuOpen ? 'translate3d(0px, 0px, 0px)' : 'translate3d(-850px, 0px, 0px)'
  })

  return (
    <nav className={styles.nav} role="navigation" aria-label="main navigation">
      <div onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <BurgerMenu isActive={isMenuOpen} />
      </div>
      <animated.div className={styles.menuContainer} style={slide}>
        <div className={styles.links}>
          <Links />
        </div>
      </animated.div>
    </nav>
  )
}
