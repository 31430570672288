import React from 'react'

export const Logo: React.FunctionComponent = () => {
  return (
    <div>
      <svg version="1.1" x="0px" y="0px" viewBox="0 0 486 95.74">
        <defs></defs>
        <g>
          <g>
            <path d="M0,0c26.44,0,47.87,21.43,47.87,47.87S26.44,95.74,0,95.74V0z" />
          </g>
          <g>
            <circle cx="104.42" cy="47.87" r="47.87" />
          </g>
          <g>
            <path d="M166.86,0c26.44,0,47.87,21.43,47.87,47.87s-21.43,47.87-47.87,47.87V0z" />
          </g>
          <g>
            <circle cx="271.27" cy="47.87" r="47.87" />
          </g>
          <g>
            <path d="M333.71,0c26.44,0,47.87,21.43,47.87,47.87s-21.43,47.87-47.87,47.87V0z" />
          </g>
          <g>
            <circle cx="438.13" cy="47.87" r="47.87" />
          </g>
        </g>
      </svg>
    </div>
  )
}
