import React from 'react'
import { Header } from '../header/Header'
import { Footer } from '../footer/Footer'

import styles from './layout.module.css'

const Layout: React.SFC = ({ children }) => {
  return (
    <div className={styles.layout}>
      <Header />
      <div className={styles.content}>{children}</div>
      <Footer />
    </div>
  )
}

export default Layout
