import React from 'react'
import { Link } from 'gatsby'

import styles from './desktop.module.css'

import { Logo } from '../../logo/Logo'
import { Links } from '../Links'

export const Desktop: React.FC = () => {
  return (
    <nav className={styles.nav} role="navigation" aria-label="main navigation">
      <div className={styles.logo}>
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className={styles.links}>
        <Links />
      </div>
    </nav>
  )
}
