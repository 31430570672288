import React, { useContext } from 'react'
import { Link } from 'gatsby'

import { myContext } from '../../provider'

export const Links: React.FC = () => {
  const context = useContext(myContext)

  return (
    <ul>
      <li className={context.activePage === 'work' ? 'activePage' : ''}>
        <Link to="/">Work</Link>
      </li>
      <li className={context.activePage === 'licensing' ? 'activePage' : ''}>
        <Link to="/licensing">Licensing</Link>
      </li>
      <li className={context.activePage === 'about' ? 'activePage' : ''}>
        <Link to="/about">About</Link>
      </li>
      <li>
        <Link to="/about#contact">Contact</Link>
      </li>
    </ul>
  )
}
