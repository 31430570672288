import { graphql, useStaticQuery } from 'gatsby'
import { ISiteMetadata } from './ISiteMetadata'

export default function useSiteMetadata(): ISiteMetadata {
  const data = useStaticQuery(graphql`
    query getMetadata {
      site {
        siteMetadata {
          siteUrl
          title
          description
          image
          twitter
        }
      }
    }
  `)
  return data.site.siteMetadata
}
