import React, { useContext, useEffect } from 'react'

import { Desktop } from './desktop/Desktop'
import { Mobile } from './mobile/Mobile'
import { myContext } from '../../provider'

export const Header = () => {
  const { windowWidth, updateWindowWidth } = useContext(myContext)

  // Store the windowWidth in the Context, so the content doesn't jump
  // on each page load.

  // On load
  useEffect(() => {
    if (typeof window !== `undefined`) {
      updateWindowWidth(window.innerWidth)
    }
  }, [updateWindowWidth])

  // On Resize
  useEffect(() => {
    const handleSetWindowWidth = () => updateWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleSetWindowWidth)
    return () => {
      window.removeEventListener('resize', handleSetWindowWidth)
    }
  }, [updateWindowWidth])

  const isLoading = windowWidth === 0
  const isMobile = windowWidth < 850

  if (isLoading) {
    return null
  }

  return <header>{isMobile ? <Mobile /> : <Desktop />}</header>
}
