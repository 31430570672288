import { graphql, useStaticQuery } from 'gatsby'
import { IMedia } from './IMedia'
import { extractFileName } from '../helpers/extractFileName'

export const useMedia = (pathname: string): IMedia | null => {
  const name = extractFileName(pathname)

  const data = useStaticQuery(graphql`
    query getReel {
      allFile(filter: { sourceInstanceName: { eq: "uploads" } }) {
        edges {
          node {
            relativePath
            publicURL
          }
        }
      }
    }
  `)

  const uploads: IMedia[] = data.allFile.edges
  const reel = uploads.filter(item => item.node.relativePath === name)[0]

  return reel ?? null
}
