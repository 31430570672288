import React from 'react'
import Helmet from 'react-helmet'
import useSiteMetadata from '../graphql/useSiteMetadata'
import { useMedia } from '../graphql/useMedia'

interface Props {
  title?: string
  description?: string
}

export const SEO: React.FC<Props> = props => {
  const siteMetadata = useSiteMetadata()
  const { siteUrl, twitter } = siteMetadata

  const lang = 'en'
  const description = props.description || siteMetadata.description
  const title = props.title || siteMetadata.title
  const image = useMedia(siteMetadata.image)

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
    >
      <meta name="description" content={description} />
      <meta name="image" content={image?.node.publicURL ?? ''} />
      {siteUrl && <meta property="og:url" content={siteUrl} />}
      <meta property="og:url" content="https://dododo.studio" />
      {/* {(article ? true : null) && <meta property="og:type" content="article" />} */}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image.node.publicURL} />}
      <meta name="twitter:card" content="summary_large_image" />
      {twitter && <meta name="twitter:creator" content={twitter} />}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image.node.publicURL} />}
    </Helmet>
    // <Helmet
    //   htmlAttributes={{
    //     lang
    //   }}
    //   title={title}
    //   titleTemplate={`%s | ${siteMetadata.title}`}
    //   meta={[
    //     {
    //       name: `description`,
    //       content: metaDescription
    //     },
    //     {
    //       property: `og:title`,
    //       content: title
    //     },
    //     {
    //       property: `og:description`,
    //       content: metaDescription
    //     },
    //     {
    //       property: `og:type`,
    //       content: `website`
    //     },
    //     {
    //       name: `twitter:card`,
    //       content: `summary`
    //     },
    //     {
    //       name: `twitter:creator`,
    //       content: `DODODO Studio`
    //     },
    //     {
    //       name: `twitter:title`,
    //       content: title
    //     },
    //     {
    //       name: `twitter:description`,
    //       content: metaDescription
    //     }
    //   ]
    //     .concat(
    //       keywords.length > 0
    //         ? {
    //             name: `keywords`,
    //             content: keywords.join(`, `)
    //           }
    //         : []
    //     )
    //     .concat(meta)}
    // />
  )
}
